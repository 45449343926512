<template>
    <transition name="modal-fade">
  <div class="modal-backdrop">
    <div class="modal riddle">
      <header class="modal-header">
        <slot name="header">
          <h1>{{ this.riddle.Name }}</h1>           
          <button v-if="this.isCompleted" type="button" class="btn-complete completed" @click="uncomplete">{{ this.translations.mark_incomplete }}</button>
          <button v-else type="button" class="btn-complete" @click="complete">{{ this.translations.mark_completed }}</button>
          <button type="button" class="btn-close" @click="close">x</button>
        </slot>
      </header>
      <div class="maincontent">
        <div class="canvas-controls">
                <p>
                    <img src="../assets/pencil.png" alt="">
                    {{ this.translations.draw_info }}                 
                </p>
                <p v-on:click="eraseAll">
                    <img src="../assets/erase.png" alt="">{{ this.translations.erase_all }}
                </p>
        </div>
        <section id="cfd-container" class="modal-body">
            <slot name="body">
            <canvas id="cfd"></canvas>
            <!--<img id="img" :src="typeof this.riddle.RiddleImage != 'undefined'? this.riddle.RiddleImage[0].url : ''" alt="">-->
            </slot>        
        </section>
       </div>
       <footer class="modal-footer w-full">
          <slot name="footer">
            <div class="modal-info">
              <div><span>{{ this.translations.type }} : </span> {{ typeof this.riddle.type != 'undefined' ? this.translations[this.riddle.type.slug] : '' }}</div>
              <div><span>{{ this.translations.difficulty }} : </span> {{ this.riddle !== null ? this.translations[this.riddle.Difficulty.toLowerCase()] : ''}}</div>
              <div><span>{{ this.translations.points }} : </span> {{ this.riddle !== null ? this.riddle.Points : null }}</div>
            </div>
            <button type="button" class="btn-green" @click="close">{{ this.translations.close_riddle }}</button>
        </slot>
      </footer>
    </div>
  </div>
  </transition>
</template>

<script>


export default {
    name: 'modal',
    props : ['riddle', 'isCompleted', 'translations'],
    /* data() {
        return {
        }
    }, */
    mounted: function() {
        // nothing to do
    },
    methods: {
      close() {
        this.$emit('close');
      },      
      complete() {
        // this.isCompleted = true;
        this.$emit('complete');  
      },
      uncomplete() {
        // this.isCompleted = false;
        this.$emit('uncomplete');
      },
      eraseAll() {
        this.$emit('redrawRiddle');
      }
    },
  };
</script>

<style>

    .modal-fade-enter,
    .modal-fade-leave-active {
        opacity: 0;
    }

  .modal-fade-enter-active,
  .modal-fade-leave-active {
    transition: opacity .5s ease
  }

  .modal-backdrop {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.3);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .modal {
    background: #FFFFFF;
    box-shadow: 2px 2px 20px 1px;
    overflow-x: auto;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
  }

  .maincontent {
      flex: 1;
  }

  .modal h1 {
    font-size: 2rem;
  }

  .modal-header,
  .modal-footer {
    padding: 15px;    
    display: flex;
    flex-shrink: 0;
  }

  .modal-header {
    border-bottom: 1px solid #eeeeee;
    color: #4AAE9B;
    justify-content: space-between;
  }

  .modal-footer {
    border-top: 1px solid #eeeeee;
    justify-content: space-around;
  }

  .modal-body {
    position: relative;
    padding: 20px 10px;
    flex: 1;
  }

  .btn-close {
    border: none;
    font-size: 2rem;
    cursor: pointer;
    font-weight: bold;
    color: #4AAE9B;
    background: transparent;
  }

  .btn-green {
    color: white;
    background: #4AAE9B;
    border: 1px solid #4AAE9B;
    border-radius: 2px;
    padding: 5px 30px;
  }

  .btn-complete {
    border: solid 1px  #4AAE9B;
    color:  #4AAE9B;
    border-radius: 2px;
    padding: 5px 30px;
  }

  .btn-complete.completed {
    border: solid 1px  #4AAE9B;
    color:  #4AAE9B;
    background-color:  #4AAE9B;
    color: white;
    border-radius: 2px;
    padding: 5px 30px;
  }

  .modal-info div {
    display: inline-block;
    margin-right: 20px;
  }

  .modal-info span {
    color: black;
  }

  .canvas-controls p {
      vertical-align: bottom;
      line-height: 32px;
      font-size: 18px;
  }

  .canvas-controls p img {
      margin-bottom: 12px;
      margin-right: 8px;
      display: inline-block;
      height: 24px;
      width: 24px;
  }
</style>