import Vue from 'vue'
import VueRouter from 'vue-router'
import VueResource from 'vue-resource'

import Login from './components/Login.vue'
import Play from './components/Play.vue'

Vue.use(VueRouter)
Vue.use(VueResource)


export default new VueRouter({
  routes: [
    {
      path: '/',
      name: 'Login',
      component: Login
    },
    {
      path: '/play',
      name: 'Play',
      component: Play
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      // component: () => import(/* webpackChunkName: "about" */ './components/play.vue')
    }
  ]
})
