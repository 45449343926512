<script>
  export default {
    name: 'howtomodal',
    props : ['language', 'howtotext', 'translations'],
    methods: {
      close() {
        this.$emit('closehowto');
      }
    },
  };
</script>

<template>
    <transition name="modal-fade">
  <div class="modal-backdrop">
    <div class="modal">
      <header class="modal-header">
        <slot name="header">
          <h1>{{ this.translations['how_to'] }}</h1>
          <button type="button" class="btn-close" @click="close">x</button>
        </slot>
      </header>
      <section class="modal-body">
        <slot name="body">
            <pre>
                {{ this.howtotext }}
            </pre>
            <!--<br>
            Reminder : 
            <br>
            Here are the defensive puzzles set up by <strong>SPECTRE</strong>. <br> 
            When answers are found, they are passed to the Team Leader who enters them onto a smartphone app called <strong>LOQUIZ</strong>. <br>
            The more puzzles you solve, the more points you win. You can sort them by nature and difficulty. <br>
            <br><br>
            NB: the cities will stay on the map even if they are saved or lost. -->
        </slot>
       </section>
       <footer class="modal-footer">
            <slot name="footer">
                <button type="button" class="btn-green" @click="close">{{ this.translations.close }}</button>
            </slot>
      </footer>
    </div>
  </div>
  </transition>
</template>

<style>
    .modal-fade-enter,
  .modal-fade-leave-active {
    opacity: 0;
  }

  .modal-fade-enter-active,
  .modal-fade-leave-active {
    transition: opacity .5s ease
  }

  .modal-backdrop {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.3);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .modal {
    background: #FFFFFF;
    box-shadow: 2px 2px 20px 1px;
    overflow-x: auto;
    display: flex;
    flex-direction: column;
  }

  .modal h1 {
    font-size: 2rem;
  }

  .modal-header,
  .modal-footer {
    padding: 15px;
    display: flex;
  }

  .modal-header {
    border-bottom: 1px solid #eeeeee;
    color: #4AAE9B;
    justify-content: space-between;
  }

  .modal-footer {
    border-top: 1px solid #eeeeee;
    justify-content: space-around;
  }

  .modal-body {
    position: relative;
    padding: 20px 10px;
  }

  .btn-close {
    border: none;
    font-size: 2rem;
    cursor: pointer;
    font-weight: bold;
    color: #4AAE9B;
    background: transparent;
  }

  .btn-green {
    color: white;
    background: #4AAE9B;
    border: 1px solid #4AAE9B;
    border-radius: 2px;
    padding: 5px 30px;
  }

  .btn-complete {
    border: solid 1px  #4AAE9B;
    color:  #4AAE9B;
    border-radius: 2px;
    padding: 5px 30px;
  }

  .btn-complete.completed {
    border: solid 1px  #4AAE9B;
    color:  #4AAE9B;
    background-color:  #4AAE9B;
    color: white;
    border-radius: 2px;
    padding: 5px 30px;
  }

  .modal-info div {
    display: inline-block;
    margin-right: 20px;
  }

  .modal-info span {
    color: black;
  }
</style>