<template>
    <div id="login" class="flex flex-col h-screen justify-center items-center" style="height: 100vh">
        <div class="w-full max-w-lg">
            <div class="leading-loose">
                <form class="max-w-sm m-4 p-10 bg-white bg-opacity-25 rounded shadow-xl" v-on:submit.prevent="authenticate">
                    <div class="mt-2">
                        <input class="w-full px-5 py-1 text-gray-700 bg-gray-300 rounded focus:outline-none focus:bg-white" type="password" id="password" v-model="password" placeholder="Enter the access code" arial-label="password" required="">
                    </div>
                    <p class="error" v-if="isWrongPassword">Incorrect password - try again</p>
                    <div class="mt-8 items-center flex justify-between">
                        <button class="px-4 py-1 text-white font-light tracking-wider bg-gray-900 hover:bg-gray-800 rounded" type="button" v-on:click="authenticate">Enter</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Login',
    props: {
        msg: String
    },
    data: function() {
        return {
            hostname: 'https://onetoteam-cyberattack-server.ads-software.net',
            session: 'alextest',
            password: '',
            isWrongPassword: false
        }
    },
    mounted: function() {
        if (window.location.hostname == "localhost") { 
            this.hostname = 'http://cyberattack.freeboxos.fr'
            this.session = 'localhost'
        } else {
            this.session = window.location.hostname.split(".")[0]
        }
    },
    methods: {
        authenticate: function() {
            this.isWrongPassword = false;
            this.$parent.$http.get(this.hostname + '/api/play?session=' + this.session + '&password=' + this.password).then(response => {
                // nothing to do
                console.log('authentication service')
                console.log(response.body)
                this.$root.$router.push({
                    name: 'Play',
                    params: response.body
                })
            }, response => {
                this.isWrongPassword = true;
                console.log('ERROR authentication')
                console.log(response)
            })
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#login {
    margin: 0; padding: 0;
    height: 100%; width: 100%;
    /* background-image: url('../assets/loginbg-virtualbackpackers.png'); */
    background-image: url('../assets/loginbg-cyberattack.jpg');
    background-size: cover;
    background-position: center;
}
.error {
    color: red;
    margin: 8px auto;
}
</style>
