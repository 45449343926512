<template>
    <div id="play" class="h-screen justify-between" style="height: 100vh">
        <Modal :riddle="currentRiddle" :isCompleted="currentRiddleIsCompleted" :translations="this.gamedata.translations" v-show="isModalVisible" @close="closeModal" @complete="completeRiddle" @uncomplete="uncompleteRiddle" @redrawRiddle="setRiddleToCanvas" />
        <HowToModal :language="this.gamedata.Language" :howtotext="this.howtotext" :translations="this.gamedata.translations" v-show="isHowtoVisible" @closehowto="closehowto" />
        <Nav 
            :language="this.gamedata.Language" 
            :translations="this.gamedata.translations" 
            :logoUrl="this.gamedata.Logo[0].url"
            v-bind:riddelCount="riddelCount" 
            v-bind:completedRiddleCount="completedRiddleCount" />
        <div id="mapcontainer" @click="handleClick">
            <div id="cybermap" ref="cybermap" class="map-cyberattack mb-auto h-10">
                <div v-show="riddle.isVisible" :ref="riddle.id" :id="riddle.id" v-for="riddle in this.gamedata.riddles" :key="riddle.id" :class="'marker marker-' + riddle.type.slug + '-' + riddle.Difficulty.toLowerCase()"></div>
                <div v-if="this.gamedata.showFiltersOnMap" id="cybermap-controls">
                    <span v-on:click="recenterMap"><img src="../assets/recenter.png" alt=""> {{ this.gamedata.translations.recenter }}</span>
                    <span v-on:click="filterRiddles('all')"><img src="../assets/marker-all.png"> {{ this.gamedata.translations.all  }}</span>
                    <span v-on:click="filterRiddles('logic')"><img src="../assets/marker-logic.png"> {{ this.gamedata.translations.logic  }}</span>
                    <span v-on:click="filterRiddles('maths')"><img src="../assets/marker-maths.png"> {{ this.gamedata.translations.maths  }}</span>
                    <span v-on:click="filterRiddles('letters')"><img src="../assets/marker-letters.png"> {{ this.gamedata.translations.letters  }}</span>
                    <span v-on:click="filterRiddles('observation')"><img src="../assets/marker-observation.png"> {{ this.gamedata.translations.observation  }}</span>
                </div>
            </div>
        </div>
        <div id="overlay" v-show="this.showIncorrectAccessCodeMsg">

        </div>
        <div id="incorrect-code" v-show="this.showIncorrectAccessCodeMsg">
            {{ this.gamedata.translations.incorrect_code }}
        </div>
        <!-- <Map 
            v-bind:gamedata="this.$route.params" 
            @updateChildData="updateChildData" /> -->
        <footer class="flex flex-row justify-between w-full h-10 bg-black text-center p-2">
            <span @click="showhowto">{{ this.gamedata.translations.reminder }}</span>
            <img v-if="this.gamedata.Audio" src="../assets/speaker.png" class="soundcontrol" alt="" v-on:click="toggleAudio">
        </footer>
    </div>
</template>

<script>
import Modal from './Modal.vue';
import HowToModal from './Howtomodal.vue';

// import HelloWorld from './components/HelloWorld.vue'
import Nav from './Nav.vue'
// import Map from './Map.vue'

import canvasFreeDrawing from '../libs/canvas-free-drawing'

export default {
    name: 'Play',
    components: {
        Nav,
        Modal,
        HowToModal
    },
    data() {
        return {
            gamedata: this.$route.params,
            riddelCount : 0,
            howtotext: '',
            completedRiddleCount: 0,
            sound: null,
            audioOn: true,
            isModalVisible: false,
            isHowtoVisible: false,
            currentRiddle: {
                Name: '',
                Difficulty: 'Easy'
            },
            currentRiddleIsCompleted: false,
            showIncorrectAccessCodeMsg: false,
            cfd: null
        }
    },
    methods: {
        showhowto: function() {
            this.isHowtoVisible = true;
        },
        closehowto: function() {
            this.isHowtoVisible = false;
        },
        initCanvas: function() {
            var container = document.getElementById('cfd-container')
            if (container.offsetWidth == 0) {
                setTimeout(this.initCanvas, 1000)
                return
            }
            // initialize
            this.cfd = new canvasFreeDrawing({
                elementId: 'cfd',
                width: container.offsetWidth,
                height: container.offsetWidth * 0.6,
            });
            // set properties
            this.cfd.setLineWidth(2); // in px
            this.cfd.setStrokeColor([21, 230, 46]); // in RGB
            // listen to events
            this.cfd.on({ event: 'redraw' }, () => {
                // code...
            });
            this.setRiddleToCanvas()
        },
        setRiddleToCanvas: function() {
            // draw background
            var canvas = document.getElementById("cfd"),
            ctx = canvas.getContext("2d");
            var background = new Image();
            background.src = this.currentRiddle.RiddleImage[0].url;
            console.log(this.currentRiddle.RiddleImage[0].url)
            background.onload = function() {
                ctx.drawImage(background,0,0, background.width, background.height, 0, 0, canvas.offsetWidth, canvas.offsetHeight);   
            }
        },
        showModal: function() {
            this.isModalVisible = true;
            // create canvas only once
            if (this.cfd == null) {
                this.initCanvas()
            } else {
                this.setRiddleToCanvas()
            }
            console.log(this.currentRiddle);
        },
        closeModal: function() {
            this.isModalVisible = false;
            this.cfd.clear();
        },
        completeRiddle: function() {
            this.currentRiddle.completed = true;
            this.currentRiddleIsCompleted = true;
            this.completedRiddleCount++;
            console.log('marking riddle ' + this.currentRiddle.id + ' as completed');
            console.log(this.currentRiddle.id)
            document.getElementById(this.currentRiddle.id).classList.add('marker-mission-completed');
            var completedRiddles = JSON.parse(localStorage.getItem('completedRiddles'));
            if (completedRiddles === null) completedRiddles = [];
            var index = completedRiddles.indexOf(this.currentRiddle.id);
            if (index == -1) {
                completedRiddles.push(this.currentRiddle.id);
            }
            localStorage.setItem('completedRiddles', JSON.stringify(completedRiddles));
        },
        uncompleteRiddle: function() {
            this.currentRiddle.completed = false;
            this.currentRiddleIsCompleted = false;
            this.completedRiddleCount--;
            document.getElementById(this.currentRiddle.id).classList.remove('marker-mission-completed');
            var completedRiddles = JSON.parse(localStorage.getItem('completedRiddles'));
            if (completedRiddles === null) completedRiddles = [];
            var index = completedRiddles.indexOf(this.currentRiddle.id);
            if (index > -1) {
                completedRiddles.splice(index, 1);
            }
            localStorage.setItem('completedRiddles', JSON.stringify(completedRiddles));
        },
        toggleAudio: function() {
            console.log('toggling sound')
            if (this.audioOn) {
                console.log('turning off audio')
                this.sound.pause();
                this.audioOn = false
            } else {
                console.log('turning on audio')
                this.sound.play();
                this.sound.loop = true;
                this.audioOn = true;
            }
        },
        handleClick(e) {
            event.stopPropagation();
            event.preventDefault();
            var i;
            if (e.target.matches('.play')) {
                let riddleId = e.target.getAttribute('data-id')
                for (i = 0 ; i < this.gamedata.riddles.length ; i++) {
                    if (this.gamedata.riddles[i].id == riddleId) {
                        console.log("Riddle found " + this.gamedata.riddles[i].Name)
                        this.currentRiddle = this.gamedata.riddles[i];
                        this.currentRiddleIsCompleted = this.gamedata.riddles[i].completed;
                        break;
                    }
                }
                this.showModal();
            }
            if (e.target.matches('.submitcode')) {
                let riddleId = e.target.getAttribute('data-id')
                for (i = 0 ; i < this.gamedata.riddles.length ; i++) {
                    if (this.gamedata.riddles[i].id == riddleId) {
                        console.log("Riddle found " + this.gamedata.riddles[i].Name)
                        var submittedcode = document.getElementById('accesscode_input_' + riddleId).value;
                        console.log('submitted code ' + submittedcode + ' / ' + this.gamedata.riddles[i].AccessCode)
                        if (submittedcode == this.gamedata.riddles[i].AccessCode) {                            
                            e.target.parentElement.parentElement.classList.remove('locked_marker_info')
                            this.unlockRiddle(riddleId)
                        } else {
                            document.getElementById('accesscode_input_' + riddleId).value = ''
                            this.showIncorrectAccessCodeMsg = true;
                            setTimeout(this.hideIncorrectAccessCodeMsg, 1500);
                        }
                        break;
                    }
                }
            }
        },
        hideIncorrectAccessCodeMsg: function() {
            this.showIncorrectAccessCodeMsg = false;
        },
        unlockRiddle: function(riddleId) {
            var tmpElt = document.getElementById(riddleId)
            if (tmpElt != null) {
                tmpElt.classList.remove('marker_locked');
            }
            var unlockedRiddles = JSON.parse(localStorage.getItem('unlockedRiddles'));
            if (unlockedRiddles === null) unlockedRiddles = [];
            var index = unlockedRiddles.indexOf(riddleId);
            if (index == -1) {
                unlockedRiddles.push(riddleId);
            }
            localStorage.setItem('unlockedRiddles', JSON.stringify(unlockedRiddles));
        },
        /* updateChildData: function(payload) {
            console.log('emit received ' + payload)
            var tmp = payload.split('=');
            if (tmp[0] == 'riddleCount') {
                this.riddelCount = parseInt(tmp[1])
            }
        },
        testclick: function() {
            alert(11)
        } */
        recenterMap: function() {
            window.map.flyTo({
                center: [0,0],
                zoom:2
            });
        },
        filterRiddles: function(riddleType) {
            console.log(riddleType)
            for (var i = 0; i < this.gamedata.riddles.length ; i++) {
                let refId = this.gamedata.riddles[i].id
                this.gamedata.riddles[i].completed = false;
                // console.log(this.gamedata.riddles[i].type.slug)
                if (this.gamedata.riddles[i].type.slug != riddleType && riddleType != 'all') {
                    this.$refs[refId][0].style.visibility = 'hidden';
                } else {
                    this.$refs[refId][0].style.visibility = '';
                }
            }
            /* var self = this;
            this.gamedata.riddles.forEach(function (riddle, index) {
                if (riddle.type.slug != riddleType && riddleType != 'all') {
                    console.log('hide')
                    self.gamedata.riddles[index].isVisible = false;
                }
            }); */
        }
    },
    mounted: function() {
        console.log("game started")
        console.log(this.gamedata)
        if (typeof this.gamedata.Name === 'undefined' || this.gamedata.Name == '') {
            console.log("Empty game data, returning to login");
            this.$root.$router.push({
                name: 'Login'
            })
            return;
        }

        console.log ('init local data')
        var completedRiddles = localStorage.getItem('completedRiddles');        
        if (completedRiddles === null) {
            completedRiddles = [];
            localStorage.setItem('completedRiddles', JSON.stringify(completedRiddles));
        } else [
            completedRiddles = JSON.parse(completedRiddles)
        ]
        console.log(completedRiddles);
        var unlockedRiddles = [];
        let unlockedRiddlesStr = localStorage.getItem('unlockedRiddles');        
        if (unlockedRiddlesStr === null) {            
            unlockedRiddlesStr = '';
            localStorage.setItem('unlockedRiddles', JSON.stringify(unlockedRiddles));            
        } else [
            unlockedRiddles = JSON.parse(unlockedRiddlesStr)
        ]
        // console.log(unlockedRiddles);
        var tmpElt, i;
        for (i = 0 ; i < completedRiddles.length; i++) {
            tmpElt = document.getElementById(completedRiddles[i])
            if (tmpElt !== null) {
                tmpElt.classList.add('marker-mission-completed');
            }
        }
        for (i = 0 ; i < unlockedRiddles.length; i++) {
            tmpElt = document.getElementById(completedRiddles[i])
            if (tmpElt !== null) {
                tmpElt.classList.remove('marker-locked');
            }
        }

        // init how to modal
        if (this.gamedata.Language == 'en') {
            this.howtotext = this.gamedata.HowTo_en
        } else {
            this.howtotext = this.gamedata.HowTo_fr
        }
        // console.log(this.gamedata)

        console.log('init audio')
        if (this.gamedata.Audio) {
            this.sound = new Audio(require('@/assets/hacking-in-a-rainy-night.ogg'));
            this.sound.loop = true;
            this.sound.play();
        }

        window.mapboxgl.accessToken = 'pk.eyJ1Ijoib25ldG90ZWFtIiwiYSI6ImNraWEzMjFiNTBscjUzMXRna3ZkbXU4MjUifQ.RtdoOAMNwvZlv6J4TIF-Lg';
        window.map = new window.mapboxgl.Map({
            container: 'cybermap',
            style: 'mapbox://styles/mapbox/dark-v10',
            center: [12.550343, 55.665957],
            zoom: 2
        });

        var self = this;
        var coords;
        var popupClass;
        this.gamedata.riddles.forEach(function(riddle, index) {
            coords = riddle.Coordinates.split(',');
            if (typeof riddle.AccessCode != "undefined" && riddle.AccessCode.length > 0 && self.gamedata.GameMode.indexOf('WorldTour') != -1 && unlockedRiddlesStr.indexOf(riddle.id) == -1) {
                popupClass = 'locked_marker_info';
                // self.gamedata.riddles[index].markerClass = 'marker_locked';
            } else {
                popupClass = 'unlocked_marker_info';
                // self.gamedata.riddles[index].markerClass = '';
            }
            self.gamedata.riddles[index].isVisible = true;
            self.gamedata.riddles[index].completed = false;
            // console.log(popupClass)
            if (completedRiddles.indexOf(riddle.id) > -1) {
                for (var j = 0 ; j < self.gamedata.riddles.length; j++) {
                    if (self.gamedata.riddles[j].id == riddle.id) {
                        self.gamedata.riddles[j].completed = true
                    }
                }
                self.completedRiddleCount++;
            }
            new window.mapboxgl.Marker(self.$refs[riddle.id][0])
                .setLngLat([coords[1], coords[0]])
                .setPopup(new window.mapboxgl.Popup({ offset: 25 })
                    .setHTML('<div class="' + popupClass+ '"><h2>' + riddle.Name + '</h2><p class="info_div"><span>' + self.gamedata.translations.category + ' : </span>' +  self.gamedata.translations[riddle.type.slug] + '<br><span>' + self.gamedata.translations.difficulty + ' : </span>' + self.gamedata.translations[riddle.Difficulty.toLowerCase()] + '<br><span>' + self.gamedata.translations.points + ' : </span>' + riddle.Points + '<br><button class="play" data-id="' + riddle.id + '">' + self.gamedata.translations.play + '</button></p><p><br></p><p class="accesscode_form">' + self.gamedata.translations.enter_access_code + '<br><input id="accesscode_input_' + riddle.id + '" type="text" class="access_code"><br><button class="submitcode" data-id="' + riddle.id + '">' + self.gamedata.translations.validate + '</button></p>'))
                .addTo(window.map);
            // markerDiv = marker.getElement();
            // markerDiv.addEventListener('mouseenter', () => marker.togglePopup());
            // markerDiv.addEventListener('mouseleave', () => marker.togglePopup());

            if (popupClass == 'locked_marker_info') {
                tmpElt = document.getElementById(riddle.id)
                if (tmpElt !== null) {
                    tmpElt.classList.add('marker_locked');
                }
            }

            // self.markers.push(marker);
            self.riddelCount = parseInt(self.riddelCount) + 1;
        }); 

    }
}

</script>

<style>
#play {
    padding: 0; margin: 0;
    width: 100%; height: 100%;
    display: flex;
    flex-direction: column;
}

/** Map */

#mapcontainer {
    flex: 1;
}

#cybermap {
    width: 100%;
    height: 100%;
    position: relative;
}

#cybermap-controls {
    position: absolute;
    z-index: 99;
    left: 20px;
    top: 20px;
    background-color: black;
    opacity: 0.5;
    color: white;
    padding: 20px
}

#cybermap-controls span {
    margin-left: 20px;
}

#cybermap-controls span img {
    margin-right: 5px;
}

#cybermap-controls img {
    width: 40px;
    display: inline;
}

.marker {
  background-image: url('../assets/marker-all.png');
  background-size: cover;
  width: 70px;
  height: 70px;
  border-radius: 50%;
  cursor: pointer;
}

.marker-mission-completed {
    opacity: 0.2; /* Real browsers */
    filter: alpha(opacity = 0.2); /* MSIE */
}

.marker_locked {
    /* background-image: url('../assets/marker-locked.png') !important; */
}

.marker-maths-easy {
    background-image: url('../assets/marker-maths-easy.png');
}

.marker-maths-medium {
    background-image: url('../assets/marker-maths-medium.png');
}

.marker-maths-difficult {
    background-image: url('../assets/marker-maths-difficult.png');
}

.marker-letters-easy {
    background-image: url('../assets/marker-letters-easy.png');
}

.marker-letters-medium {
    background-image: url('../assets/marker-letters-medium.png');
}

.marker-letters-difficult {
    background-image: url('../assets/marker-letters-difficult.png');
}

.marker-observation-easy {
    background-image: url('../assets/marker-observation-easy.png');
}

.marker-observation-medium {
    background-image: url('../assets/marker-observation-medium.png');
}

.marker-observation-difficult {
    background-image: url('../assets/marker-observation-difficult.png');
}

.marker-logic-easy {
    background-image: url('../assets/marker-logic-easy.png');
}

.marker-logic-medium {
    background-image: url('../assets/marker-logic-medium.png');
}

.marker-logic-difficult {
    background-image: url('../assets/marker-logic-difficult.png');
}

.mapboxgl-popup {
    max-width: 600px;
    font: 18px/20px 'Helvetica Neue', Arial, Helvetica, sans-serif;
}

.mapboxgl-popup-content {
  text-align: center;
  font-family: 'Open Sans', sans-serif;
  padding: 20px 30px;
}

.mapboxgl-popup-content h2{
    font-weight: bold;
    font-size: 1.2em;
    margin-left: -10px;
    margin-bottom: 10px;
}

.mapboxgl-popup-close-button {
    top: 8px !important;
    right: 4px !important;
    font-size: 1.2em !important;
}

.mapboxgl-popup-content span{
    color: darkgray;
}

.mapboxgl-popup-content button.play,
.mapboxgl-popup-content button.submitcode {
    margin-top: 10px;
    background-color: black;
    color: lightgreen;
    padding: 2px 12px;
    border-radius: 4px;
}

.accesscode_form {
    display: none;
}

.accesscode_form input {
    border: solid 1px darkgray;
}

.locked_marker_info .accesscode_form {
    display: block;
}

.locked_marker_info .info_div {
    /* display: none; */
}

.locked_marker_info button.play {
    display: none;
}

footer {
    color: #15e62e;
    align-self: end;
    flex-direction: row;
}

.soundcontrol {
    display: inline;
    float: right;       
    max-height: 100%; 
}

/** Incorrect code Modal box **/

#overlay {
    position: absolute; 
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.6;
    background-color: darkgray;
    z-index: 101;
}

#incorrect-code {
    background-color: white;
    box-shadow: 0 0 10px 0 rgba(151, 144, 144, 0.1);
    border-radius: 10px;
    padding: 30px 80px;
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 102;
}

/** Modal Box **/

.modal-backdrop {
    z-index: 100;
}

.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal {
  overflow-y: scroll;
  max-width: 1800px;
  width: 90%;
  margin: 0px auto;
  padding: 20px 30px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  font-family: Helvetica, Arial, sans-serif;
}

.modal.riddle {
    height: 100%;
}

.modal-header h3 {
  margin-top: 0;
  color: #42b983;
}

.modal-body {
  margin: 20px 0;
}

.modal-default-button {
  display: block;
  margin-top: 1rem;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>

