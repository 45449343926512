<template>
  <nav class="flex items-center justify-between bg-black p-6" style="color: #15e62e">
    <div class="text-white mr-6">
      <img class="logo block" :src="this.getLogoPath()" alt="">
    </div>
    <div class="w-full text-right" style="padding: 16px">
      {{ this.translations.puzzles }} : {{ completedRiddleCount }} / {{ riddelCount }}
    </div>
  </nav>
</template>

<script>
export default {
  name: 'Nav',
  props: [
    'language',
    'logoUrl',
    'riddelCount',
    'completedRiddleCount',
    'translations'
  ],
  methods: {
    getLogoPath: function() {
      return this.logoUrl;
      // return require('../assets/logo_' + this.language + '.png')
    }
  }
}
</script>

<style scoped>
  .logo {
    max-height: 100px;
  }
</style>